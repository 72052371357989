<template>
  <div id="app">
    <h1 class="mt-5 ml-5"  >Employees</h1>
    <!-- all the columns are inside the row -->
    <v-row class="my-15">
      <v-col cols="3" >
        <v-text-field class="ml-1 mr-1" label="First Name" solo dense v-model="firstName"></v-text-field>
      </v-col>
      <v-col cols="3" >
        <v-text-field class="ml-1 mr-1" label="Last Name" solo dense v-model="lastName"></v-text-field>
      </v-col>
      <v-col cols="3" >
        <v-text-field class="ml-1 mr-1" label="Phone" solo dense v-model="phone"></v-text-field>
      </v-col>
      <v-col cols="3" >
        <v-text-field class="ml-1 mr-1" label="Email" solo dense v-model="email"></v-text-field>
      </v-col>
      <v-col>
       <v-btn rounded color="green" dark @click="submitEmployee" icon><v-icon large>mdi-location-enter</v-icon></v-btn>
      </v-col>   
    </v-row>     
    <v-simple-table fixed-header fill-height="300px" class="my-15">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left blue lighten-2 white--text"><h2>Employee Name</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2>Phone</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2>Email</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2></h2></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in emp" :key="index">
            <td class="text-left">{{ item.firstName }} {{ item.lastName }}</td>
            <td class="text-left">{{ item.phone }}</td>
            <td class="text-left">{{ item.email }}</td>
            <v-btn color="primary" dark @click="editItem(index)" icon><v-icon>mdi-circle-edit-outline</v-icon></v-btn>
            <v-btn class="mr-4" rounded color="red" dark @click="deleteItem(index)" icon><v-icon>mdi-delete-circle-outline</v-icon></v-btn>    
          </tr>
        </tbody> 
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      value: '',
      listItems: [],
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      updateTask: null 
    }
  },
    computed: {
    ...mapGetters({
      emp: "empInfo/emp"
    }),
    
  },
    async mounted() {
        this.$store.dispatch("empInfo/getEmp");
        // console.log(this.emp[0].firstName)
        
    },   
  methods: {
                ...mapActions({
          deleteEmpVuex: 'empInfo/deleteEmp',
        }),

    submitEmployee(){
      
      // console.log('updateTask is ' + this.updateTask)
      if (!this.firstName || !this.lastName ) {return}
        // if updateTask does not hv a value, then it is in add mode
      if( this.updateTask === null ){
            //add a record
      this.createEmployee()
        // if updateTask has a value, then it is in update mode
      } else {
            //update a record
      this.updateEmployee(this.updateTask)
      }
    },
    async createEmployee(){
        const { firstName,lastName,phone,email } = this;
        if (!firstName || !lastName) return;
        const newEmp = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email
        }
        
        
        this.$store.dispatch("empInfo/createEmp", newEmp);
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.email = '';
        this.updateTask = null
    },
    async updateEmployee(index){
            const input= {
                id: this.emp[index].id,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                email: this.email  
            }
            this.$store.dispatch("empInfo/updateEmp", input);
        this.firstName = '';
        this.lastName = '';
        this.phone = '';
        this.email = '';
        this.updateTask = null
    },
    async deleteItem(index) {
        //use try/catch to get feedback if there's any errors...otherwise you don't know what the errors are
        try {
            this.deleteEmpVuex(this.emp[index].id);

        } catch(err) {
            console.log(err)
        }
        //delete from memory (listItems array)
        this.listItems.splice(index, 1)    
    },
    editItem(index){
      this.firstName = this.emp[index].firstName;
      this.lastName = this.emp[index].lastName;
      this.phone = this.emp[index].phone;
      this.email = this.emp[index].email;
      this.updateTask = index;
    }

  } 
};
</script>
<style>
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>