import { API, graphqlOperation } from 'aws-amplify';
import { createCustomer, updateCustomer, deleteCustomer } from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export const custInfo = {
    namespaced: true,
    state: { cust: null },
    mutations: {
        setCust(state, payload) {
            state.cust = payload;
        }
    },
    actions: {
          async createCust({ dispatch }, newCust) {
            try {
                await API.graphql(graphqlOperation(createCustomer, { input: newCust }))
                dispatch("getCust");
            } catch (error) {
                console.error("create cust", error)
            }
            console.log('adding')
          },
          async getCust({ commit }) {
              console.log("getCust")
            const custData = await API.graphql(graphqlOperation(queries.listCustomers));
            custData.data.listCustomers.items = custData.data.listCustomers.items.sort((a,b)=> a.company.localeCompare (b.company))
            commit("setCust", custData.data.listCustomers.items);
        },
        async updateCust({ dispatch }, input) {
            try {
                await API.graphql(graphqlOperation(updateCustomer, { input: input }))
                dispatch("getCust");
            } catch (error) {
                console.error("update cust", error)
            }
            console.log('updateCust')
        },
        async deleteCust({ dispatch }, input) {
            try {
                await API.graphql(graphqlOperation(deleteCustomer, { input: { id: input } }))
                dispatch("getCust");
            } catch (error) {
                console.error("delete cust", error)
            }
            console.log('deleteCust')
        },
    },
    getters: {
        cust: (state) => state.cust
    }
}