<template>
  <div> 
             <!-- Calendar toolbar -->
        <v-toolbar flat color="white">
          <v-btn
            color="primary"
            class="mr-4"
            @click="dialogCreateEvent = true"
            dark
            >Schedule
            </v-btn>
          <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
            >Today</v-btn
          > -->
          <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()"
            ><v-icon small>mdi-chevron-left</v-icon></v-btn>
          <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()"
            ><v-icon small>mdi-chevron-right</v-icon></v-btn>
          <v-toolbar-title v-if="$refs.calendar">{{$refs.calendar.title}}</v-toolbar-title>
           </v-toolbar>
          <v-spacer></v-spacer>
     <!-- Create Event dialog -->
        <v-dialog v-model="dialogCreateEvent" max-width="500">
          <!-- <v-card v-bind:class="dialog_color"> -->
          <v-card >
            <v-container>
              <v-alert v-if="showError" dense type="error">Required info missed or wrong date chosen *</v-alert>
                <!-- EH3 -->
               <v-row class="mt-5">
                <v-col>
                <v-text-field
                  @click="resetError"
                  v-model="start"
                  type="date"
                  label="Start Date of Service* (*=required)"
                ></v-text-field>
                </v-col>
                <v-col>
                <v-text-field
                  @click="resetError"
                  v-model="end"
                  type="date"
                  label="End Date of Service* (*=required)"
                ></v-text-field>
                </v-col>
              </v-row> 
                <v-row @click="resetError">
                  <v-col class="text-left" cols="8" sm="4" md="4">
                    <v-label>Bill Type*</v-label>
                    <v-radio-group  v-model="billType" column>
                      <v-radio label="Billable" color="blue" value="Billable"></v-radio>
                      <v-radio label="Non Billable" color="blue " value="NonBillable"></v-radio>
                    </v-radio-group>
                    <v-text-field
                        class="mt-5"
                        v-model="billDate"
                        type="date"
                        label="Bill Date"
                    ></v-text-field>
                    <v-text-field
                        class="mt-5"
                        v-model="billPaidDate"
                        type="date"
                        label="Bill Paid Date"
                    ></v-text-field>
                  </v-col>
                  <v-col class="text-left ml-16"  cols="4">
                    <v-label>Service Category*</v-label>
                    <v-radio-group v-model="serviceCategory" column>
                      <v-radio label="Carpet" color="blue" value="Carpet"></v-radio>
                      <v-radio label="Vinyl" color="blue " value="Vinyl"></v-radio>
                      <v-radio label="Naildown" color="blue " value="Naildown"></v-radio>
                      <v-radio label="Tile" color="blue " value="Tile"></v-radio>
                      <v-radio label="Misc" color="blue " value="Misc"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-container fluid>
                  <v-row  align="center">
                    <v-col class="d-flex" cols="12" sm="6" ><v-select v-model="customer" :items="custSelect" label="Customer*" solo></v-select></v-col>
                    <v-col class="d-flex" cols="12" sm="6" ><v-select v-model="employee" :items="empSelect"  label="Technician*" solo></v-select></v-col>
                  </v-row>
                </v-container>
                <v-text-field
                  v-model="jobDetail"
                  type="text"
                  label="Job Detail"
                ></v-text-field>
                <v-btn
                  type="submit"
                  color="primary"
                  class="mr-4"
                  @click="createEvent"
                  >Create Event</v-btn
                >
                <!-- <v-btn @click="debug">Debug</v-btn> -->

            </v-container>
          </v-card>
        </v-dialog>  
    <v-sheet
      tile
      height="54"
      class="d-flex"
    >
    
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="type"
      ></v-select>
      <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change="getEvents"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        :first-interval="23"
        :interval-minutes="480"
        :interval-height="1"
        :event-margin-bottom="2"
        
        
      ></v-calendar>
       <!-- Editing the Event -->
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          :open-on-click="false"
        >
          <v-card color="grey lighten-4" class="mb-6" max-width="500" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn  @click.prevent="dialogDeleteConfirmation = true" icon><v-icon>mdi-delete</v-icon></v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </v-toolbar>
            
            <v-card-text>
             
              <!-- Edit Selected Event -->
              <form >
                <div>
                   <v-container>
              <!-- <v-alert v-if="showError" dense type="error">Required info missed or wrong date chosen *</v-alert> -->
                <!-- EH3 -->
               <v-row class="mt-5">
                <v-col>
                <v-text-field
                  @click="resetError"
                  v-model="selectedEvent.start"
                  type="date"
                  label="Start Date of Service"
                ></v-text-field>
                </v-col>
                <v-col>
                <v-text-field
                  @click="resetError"
                  v-model="selectedEvent.end"
                  type="date"
                  label="End Date of Service"
                ></v-text-field>
                </v-col>
              </v-row> 
                <v-row @click="resetError">
                  <v-col class="text-left" cols="8" sm="4" md="4">
                    <v-label>Bill Type</v-label>
                    <v-radio-group  v-model="selectedEvent.billType" column>
                      <v-radio label="Billable" color="blue" value="Billable"></v-radio>
                      <v-radio label="Non Billable" color="blue " value="NonBillable"></v-radio>
                    </v-radio-group>
                    <v-text-field
                        class="mt-5"
                        v-model="selectedEvent.billDate"
                        type="date"
                        label="Bill Date"
                    ></v-text-field>
                    <v-text-field
                        class="mt-5"
                        v-model="selectedEvent.billPaidDate"
                        type="date"
                        label="Bill Paid Date"
                    ></v-text-field>
                  </v-col>
                  <v-col class="text-left ml-16"  cols="4">
                    <v-label>Service Category</v-label>
                    <v-radio-group v-model="selectedEvent.serviceCategory" column>
                      <v-radio label="Carpet" color="blue" value="Carpet"></v-radio>
                      <v-radio label="Vinyl" color="blue " value="Vinyl"></v-radio>
                      <v-radio label="Naildown" color="blue " value="Naildown"></v-radio>
                      <v-radio label="Tile" color="blue " value="Tile"></v-radio>
                      <v-radio label="Misc" color="blue " value="Misc"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-container fluid>
                  <v-row  align="center">
                    <v-col class="d-flex" cols="12" sm="6" ><v-select v-model="selectedEvent.customer" :items="custSelect" label="Customer*" solo></v-select></v-col>
                    <v-col class="d-flex" cols="12" sm="6" ><v-select v-model="selectedEvent.employee" :items="empSelect"  label="Technician*" solo></v-select></v-col>
                  </v-row>
                </v-container>
                <v-text-field
                  v-model="selectedEvent.jobDetail"
                  type="text"
                  label="Job Detail"
                ></v-text-field>
                 </v-container>
                </div>
              </form>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="red" @click="closeEvent">Close</v-btn>
              <v-btn text color="blue" @click.prevent="updateEvent(selectedEvent)">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- Delete Confirmation -->
          <v-row justify="center">
            <v-dialog v-model="dialogDeleteConfirmation" persistent max-width="290">
              <v-card>
                <v-card-title class="error headline" style="font-weight:bold; color:white;">Confirm Delete</v-card-title>
                <v-card-text>Are you sure you want to delete? This cannot be undone.</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogDeleteConfirmation = false">Close</v-btn>
                  <v-btn color="error" text @click="deleteEvent(selectedEvent)">Confirm Delete</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- Cancel Confirmation -->
          <v-row justify="center">
            <v-dialog v-model="dialogCancelConfirmation" persistent max-width="290">
              <v-card>
                <v-card-title class="black headline" style="font-weight:bold; color:white;">Confirm Cancel</v-card-title>
                <v-card-text>Are you sure you want to cancel? This cannot be undone. You will have to resubmit if you feel you still need it afterwards.</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogCancelConfirmation = false">Close</v-btn>
                  <v-btn color="error" text @click="cancelEvent(selectedEvent)">Confirm Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- Complete Confirmation -->
          <v-row justify="center">
            <v-dialog v-model="dialogCompleteConfirmation" persistent max-width="290">
              <v-card>
                <v-card-title class="blue headline" style="font-weight:bold; color:white;">Confirm Complete</v-card-title>
                <v-card-text>Are you sure you want to set status to complete? This cannot be undone.</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogCompleteConfirmation = false">Close</v-btn>
                  <v-btn color="blue" text @click="completeEvent(selectedEvent)">Confirm Complete</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-menu>
    </v-sheet>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import * as queries from "../graphql/queries";
// import { listCalEvents } from "@/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {deleteCalEvent} from "@/graphql/mutations";
  export default {
    data: () => ({
        color: 'blue',
        custSelect: [],
        empSelect: [],
        showError: "",
        start: "",
        end: "",
        billType: "",
        billDate: "",
        billPaidDate: "",
        serviceCategory: "",
        jobDetail: "",
        customer: "",
        customers: "",
        employee: "",
        employees: "",
        events: [],
        selectedEvent: {},
        currentlyEditing: null,
      selectedElement: null,
      selectedOpen: false,
      categories: [],
      dialogCreateEvent: false,
      dialogDeleteConfirmation: false,
      dialogCancelConfirmation: false,
      dialogCompleteConfirmation: false, 
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
    //   events: [],
      // colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }),
    computed: {
    ...mapGetters({
      emp: "empInfo/emp",
      cust: "custInfo/cust",
      calevent: "calEventInfo/calevent"
    }),
  },
    async created() {
        // this.queryCalEvent();
        this.$store.dispatch("empInfo/getEmp");
        this.$store.dispatch("custInfo/getCust");
        this.$store.dispatch("calEventInfo/getCalEvent");
        // this.$store.dispatch("updateCalEvent/getCalEvent");
        // console.log(this.emp[0].firstName);
        // console.log(this.cust[0].company);
        
    }, 

    methods: {
       async createEvent(){
        // console.log(this.color)
        //  color hiearchy - default (blue), billable (red), billed (yellow), paid (green)
        if(this.selectedEvent.billType === 'NonBillable'){this.color = 'blue'} 
        // console.log(this.selectedEvent.billType)
       
        // console.log(this.color)
        if(this.billType === 'Billable'){this.color = 'red'} 
        
        if (this.billDate !== ''){this.color = 'orange'} 
        
        if (this.billPaidDate){this.color = 'green'}

        if (this.end === '' ){this.start}

        const { start, end, customer, employee, billType, billDate, serviceCategory,jobDetail, color, billPaidDate} = this;
        if (!start ||!customer ||!employee ||!serviceCategory) return;
        const newCalEvent = {
            start: start,
            end: end,
            customer: customer,
            employee: employee,
            billType: billType,
            billDate: billDate,
            serviceCategory: serviceCategory,
            jobDetail: jobDetail,
            color: color,
            billPaidDate: billPaidDate,

        }
        this.$store.dispatch("calEventInfo/createCalEvent", newCalEvent);
        this.start = '';
        this.end = '';
        this.customer = '';
        this.employee = '';
        this.billType = '';
        this.billDate = '';
        this.serviceCategory = '';
        this.jobDetail = '';
        this.color = 'blue';
        this.billPaidDate = '';
        this.dialogCreateEvent=false

       },

       async updateEvent(selectedEvent){
        this.color = this.selectedEvent.color
        if(this.selectedEvent.billType === 'NonBillable'){this.color = 'blue'} 
        if(this.selectedEvent.billType === 'Billable'){this.color = 'red'} 
        
        if (this.selectedEvent.billDate !== ''){this.color = 'orange'} 
        
        if (this.selectedEvent.billPaidDate){this.color = 'green'}
            const input= {
                id: selectedEvent.id,
            start: selectedEvent.start,
            end: selectedEvent.end,
            color: this.color,
            jobDetail: selectedEvent.jobDetail,
            customer: selectedEvent.customer,
            employee: selectedEvent.employee,
            serviceCategory: selectedEvent.serviceCategory,
            billDate: selectedEvent.billDate,
            billType: selectedEvent.billType,
            billPaidDate: selectedEvent.billPaidDate
            }
            this.$store.dispatch("calEventInfo/updateCalEvent", input);
            this.color = 'blue';
        
    },
    async deleteEvent() {
      // console.log(this.selectedEvent.id)
        //use try/catch to get feedback if there's any errors...otherwise you don't know what the errors are
        
        
        try {
            await API.graphql(graphqlOperation(deleteCalEvent, { input: { id: this.selectedEvent.id }}))
            // await API.graphql(graphqlOperation(deleteTodo,     {input:  { id: this.listItems[index].id }}));

            } catch (error) {
                console.error("delete calevent", error)
            }
          this.selectedOpen = false
        // try {
        //     this.$store.dispatch("calEventInfo/deleteCalEvent", this.selectedEvent.id);

        // } catch(err) {
        //     console.log(err)
        // }
        //delete from memory (listItems array)
        this.events.splice(this.selectedEvent.id, 1)    
    },
      
      showEvent({ nativeEvent, event }) {
      console.log("you clicked showEvent")
      // console.log("this.selectedEventcolor = " + this.selectedEvent.color)
      // console.log("this.event.color = " + event.color)
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.currentTarget;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
      // this.before_details_value = this.selectedEvent.details;
      // this.before_emp_notes_value = this.selectedEvent.emp_notes;
      // this.before_admin_notes_value = this.selectedEvent.admin_notes;
      // this.before_category_value = this.selectedEvent.category;
      // this.before_color_value = this.selectedEvent.color
      },
      closeEvent(){
      this.selectedOpen = false
      },
      viewDay({ date }) {
      this.focus = date;
      this.type = "day";
      },
      // async deleteEvent(ev) {
      // console.log("Delete Event")
      // const calEventDetails = { id: ev.id };
      // await API.graphql({
      //   query: deleteCalEvent,
      //   variables: { input: calEventDetails },
      // });
      // this.dialogDeleteConfirmation = false;
      // this.selectedOpen = false;
      // this.currentlyEditing = null;
      // this.getCalEvents();
    // },
    resetError(){
        // this.dialogCreateEvent = false
        console.log("resetError")
    },

    debug(){
        // this.dialogCreateEvent = false
        console.log("debug")
        // console.log(this.emp[0].firstName)
    },
    getEvents () {
    
      },
      getEventColor (event) {
        return event.color
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
    },
    watch: {
      emp: function(){
        this.emp.forEach(element => {
          this.empSelect.push(
            element.firstName + " " + element.lastName
          )
        });
      },
      cust: function(){
        this.cust.forEach(element => {
          this.custSelect.push(
            element.company
          )
        });
      },
      calevent: function(){
        this.events=[]
        this.calevent.forEach(item =>{
          this.events.push({
            id: item.id,
            name: item.customer + ' - ' + item.employee + ' - ' + item.serviceCategory + ' - ' + item.billType,
            start: item.start,
            end: item.end,
            color: item.color,
            jobDetail: item.jobDetail,
            customer: item.customer,
            employee: item.employee,
            serviceCategory: item.serviceCategory,
            billDate: item.billDate,
            billType: item.billType,
            billPaidDate: item.billPaidDate
          })
           this.selectedOpen = false
        })
      }
    }
  }
</script>