import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth.js';
import { empInfo } from './emp/emp.js';
import { custInfo } from './cust/cust.js';
import { calEventInfo } from './calevent/calevent.js';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth, empInfo,custInfo, calEventInfo
    
  }
})