<template>
    <v-app>
    <v-app-bar app color="primary" dark>
      <v-tabs
        background-color="primary"
        dark
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
      >
      <!-- show tabs if user is admin -->
        <v-tab to="/customer">Customer</v-tab>
        <v-tab to="/employee">Employee</v-tab>
        <v-tab to="/calendar">Calendar</v-tab>
        <v-tab v-if="user" @click="signOut">Logout</v-tab>
      </v-tabs>
      <!-- welcome username -->
      <h4 v-if="user" class="text-right text-no-wrap">Welcome {{ user.username }}!</h4>
    </v-app-bar>
    <!-- <NavBar/> -->
    <!-- <amplify-authenticator> -->
    <!-- the parent will listen to the childClicked from child-Summary.vue 
    listen for @childClicked and run onClickChild (function)
    props send to the child is the textEvent="msg" which is the items-->
    <v-main>
      <!-- <amplify-authenticator> -->
        <router-view/>
      <!-- </amplify-authenticator> -->
      <!-- <amplify-sign-out></amplify-sign-out> -->
      <!-- <Login /> -->
    </v-main>
        <!-- <amplify-authenticator>
          <amplify-sign-in slot="sign-in" :hide-sign-up="true"></amplify-sign-in>
        </amplify-authenticator>
        <amplify-sign-out v-if="authState === 'signedin'" ></amplify-sign-out> -->
  </v-app>
</template>
<script>
// import Nav from "@/components/Nav.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data:()=>({

  }),
  components: {
    // Nav  
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    })
  },
  async mounted() {
    this.$store.dispatch("auth/authAction");
  }, 
  methods:{
            ...mapActions({
          logoutVuex: 'auth/logout',
        }),
    async signOut(){
      await this.logoutVuex()
      this.$router.push("/")
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>