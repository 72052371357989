<template>
  <div id="app">
    <h1 class="mt-5 ml-5"  >Customers</h1>
    <!-- all the columns are inside the row -->
    <v-row class="my-15">
      <v-col cols="4" >
        <v-text-field class="mx-5" label="Company" solo dense v-model="company"></v-text-field>
      </v-col>
      <v-col cols="4" >
        <v-text-field class="mx-5" label="Contact" solo dense v-model="contact"></v-text-field>
      </v-col>
      <v-col cols="3" >
        <v-text-field class="mx-5" label="Phone" solo dense v-model="phone"></v-text-field>
      </v-col>
      <v-col cols="4" >
        <v-text-field class="mx-5" label="Email" solo dense v-model="email"></v-text-field>
      </v-col>
      <v-col cols="6" >
        <v-text-field class="mx-5" label="Address" solo dense v-model="address"></v-text-field>
      </v-col>
      <v-col>
       <v-btn rounded color="green" dark @click="submitCustomer" icon><v-icon large>mdi-location-enter</v-icon></v-btn>
      </v-col>   
    </v-row>     
    <v-simple-table fixed-header fill-height="300px" class="my-15">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left blue lighten-2 white--text"><h2>Company</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2>Contact</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2>Phone</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2>Email</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2>Address</h2></th>
            <th class="text-left blue lighten-2 white--text"><h2></h2></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in cust" :key="index">
            <td class="text-left">{{ item.company }}</td>
            <td class="text-left">{{ item.contact }}</td>
            <td class="text-left">{{ item.phone }}</td>
            <td class="text-left">{{ item.email }}</td>
            <td class="text-left">{{ item.address }}</td>
            <v-btn color="primary" dark @click="editItem(index)" icon><v-icon>mdi-circle-edit-outline</v-icon></v-btn>
            <v-btn class="mr-4" rounded color="red" dark @click="deleteItem(index)" icon><v-icon>mdi-delete-circle-outline</v-icon></v-btn>    
          </tr>
        </tbody> 
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      listItems: [],
      company: "",
      contact: "",
      phone: "",
      email: "",
      address: "",
      updateTask: null 
    }
  },
    computed: {
    ...mapGetters({
      cust: "custInfo/cust"
    }),
  },
    async mounted() {
        this.$store.dispatch("custInfo/getCust");
        console.log(this.cust)
    },   
  methods: {
    
    submitCustomer(){
      console.log('updateTask is ' + this.updateTask)
      if (!this.company ) {return}
        // if updateTask does not hv a value, then it is in add mode
      if( this.updateTask === null ){
            //add a record
      this.createCustomer()
        // if updateTask has a value, then it is in update mode
      } else {
            //update a record
      this.updateCustomer(this.updateTask)
      }
    },
    async createCustomer(){
        const { company,contact,phone,email, address} = this;
        if (!company ) return;
        const newCust = {
            company: company,
            contact: contact,
            phone: phone,
            email: email,
            address: address
        }
        
        this.$store.dispatch("custInfo/createCust", newCust);
        this.company = '';
        this.contact = '';
        this.phone = '';
        this.email = '';
        this.address = '';
        this.updateTask = null
    },
    async updateCustomer(index){
            const input= {
                id: this.cust[index].id,
                company: this.company,
                contact: this.contact,
                phone: this.phone,
                email: this.email,
                address: this.address   
            }
            this.$store.dispatch("custInfo/updateCust", input);
        this.company = '';
        this.contact = '';
        this.phone = '';
        this.email = '';
        this.address = '';
        this.updateTask = null
    },
    async deleteItem(index) {
        //use try/catch to get feedback if there's any errors...otherwise you don't know what the errors are
        try {
            this.$store.dispatch("custInfo/deleteCust", this.cust[index].id);

        } catch(err) {
            console.log(err)
        }
        //delete from memory (listItems array)
        this.listItems.splice(index, 1)    
    },
    editItem(index){
      this.company = this.cust[index].company;
      this.contact = this.cust[index].contact;
      this.phone = this.cust[index].phone;
      this.email = this.cust[index].email;
      this.address = this.cust[index].address;
      this.updateTask = index;
    }

  } 
};
</script>
<style>
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>