import { API, graphqlOperation } from 'aws-amplify';
import { createEmployee, updateEmployee, deleteEmployee } from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export const empInfo = {
    namespaced: true,
    state: { emp: null },
    mutations: {
        setEmp(state, payload) {
            state.emp = payload;
        }
    },
    actions: {
          async createEmp({ dispatch }, newEmp) {
            try {
                await API.graphql(graphqlOperation(createEmployee, { input: newEmp }))
                dispatch("getEmp");
            } catch (error) {
                console.error("create emp", error)
            }
            console.log('adding')
          },
          async getEmp({ commit }) {
              console.log("getEmp")
            const empData = await API.graphql(graphqlOperation(queries.listEmployees));
            empData.data.listEmployees.items = empData.data.listEmployees.items.sort((a,b)=> a.firstName.localeCompare (b.firstName))
            commit("setEmp", empData.data.listEmployees.items);
            
        },

        async updateEmp({ dispatch }, input) {
            try {
                await API.graphql(graphqlOperation(updateEmployee, { input: input }))
                dispatch("getEmp");
            } catch (error) {
                console.error("update emp", error)
            }
            console.log('updateEmp')   
        },
        async deleteEmp(input) {
            // async deleteEmp( input) {
                try {
                await API.graphql(graphqlOperation(deleteEmployee, { input: { id: input } }))
                // dispatch("getEmp");
            } catch (error) {
                console.error("delete emp", error)
            }
            console.log('deleteEmp')
        },
    },
    getters: {
        emp: (state) => state.emp
    }
}