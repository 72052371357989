<template>
    <v-app>
    <!-- Login Dialog Box  -->
      <v-dialog v-model="dialogLogin" persistent>
        <v-card >
          <v-container style="width=50%" fluid>
            <v-row justify="center" align="center" no-gutters>
              <v-col cols=12 md=4>
                <v-text-field class="ma-3 mt-5 font-weight-bold" v-model="userName" label="Username (no spaces)" outlined rounded></v-text-field>              
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col cols=12 md=4>
                <v-text-field  class="mx-3 font-weight-bold" v-model="userPassword" type="password"  label="Password" outlined rounded></v-text-field>
                <h3 v-if="errorLogin" align=center class="font-weight-bold red--text">{{ errorLogin }}</h3>
              </v-col>  
            </v-row>

            <!-- <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="createEntry" rounded>Debug</v-btn>
             </v-row>             -->
             <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="signin" rounded>Login<v-icon color="white">mdi-login</v-icon></v-btn>
             </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="dialogRegister=true" rounded>Register<v-icon color="white" >mdi-account-plus</v-icon></v-btn>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-1" color="black" dark @click="dialogForgot=true" rounded>Reset Password<v-icon color="white" >mdi-lock-reset</v-icon></v-btn>              
            </v-row>
            <!-- <v-row justify="center" no-gutters>
                <h4 class="white--text mt-2 mx-7">Note: Upload photos meant ONLY for Internet consumption</h4>              
            </v-row>                -->
          </v-container>
        </v-card>          
        <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>

      </v-dialog>
    <!-- Register Dialog Box  -->
      <v-dialog v-model="dialogRegister" persistent>
        <v-card>
          <v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters> 
              <v-col  cols=12 md=6>
                  <v-text-field :rules="[rules.required, rules.email]" class="mx-3" v-model="userEmail" type="email" label="Enter Email Address" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                  <v-text-field :rules="[rules.required, rules.blank]" class="mx-3" v-model="userName" label="Username" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                  <v-text-field :rules="[rules.required]" class="mx-3" v-model="userPassword" type="password"  label="Password" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=6>
                <h3 v-if="errorRegister" align=center class="red--text">{{ errorRegister }}</h3>
              </v-col>
            </v-row>            
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="register" rounded>Register<v-icon color="white">mdi-account-plus</v-icon></v-btn>
                <v-btn class="ma-4" color="black" dark @click="backToLogin" rounded>Back<v-icon color="white">mdi-arrow-left-circle</v-icon></v-btn>
            </v-row>
          </v-container>
          <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
        </v-card>
      </v-dialog>
    <!-- Signup Confirmation  -->
      <v-dialog v-model="dialogConfirm" persistent>
        <v-card><v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <v-card-text align="center" class="mt-7 white--text">Please check your email for confirmation code</v-card-text>
              <v-col cols=12 md=4>
                <v-text-field class="ma-3 mt-1 font-weight-bold" v-model="confirmCode"  label="Code" outlined rounded></v-text-field>
                <h3 v-if="errorConfirm" align=center class="font-weight-bold red--text">{{ errorConfirm }}</h3>
                <h3 v-if="errorResendCode" align=center class="font-weight-bold red--text">{{ errorResendCode }}</h3>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="confirmSignUp" rounded>Confirm<v-icon color="white">mdi-ticket-confirmation</v-icon></v-btn>
                <v-btn class="ma-4" color="black" dark @click="resendCode" rounded>Re-send Code<v-icon color="white">mdi-email-sync-outline</v-icon></v-btn>
            </v-row></v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
            </v-card>
      </v-dialog>
    <!-- Forgot Password  -->
      <v-dialog v-model="dialogForgot">
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
          <v-row justify="center">
            <v-col cols=12 md=6>
              <h3 align=center class="white--text">Reset Codes are sent ONLY to player's parents</h3>
            </v-col>
          </v-row><v-row justify="center" no-gutters>
            <v-col  cols=12 md=4>
              <v-text-field class="ma-3 mt-10 font-weight-bold" v-model="userName"   label="Enter User Name" outlined rounded></v-text-field>
              <h3 v-if="errorForgot" align=center class="font-weight-bold red--text">{{ errorForgot }}</h3>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
              <v-btn class="ma-4" color="black" dark @click="forgot" rounded>Reset Password<v-icon color="white">mdi-ticket-confirmation</v-icon></v-btn>
          </v-row></v-container></v-card>
      </v-dialog>
    <!-- Forgot Password Submit -->
      <v-dialog v-model="dialogForgotSubmit" persistent>
        <v-card :style="{'background-image':'url(/window.jpg)', 'background-size':'cover', 'margin':'0', 'padding':'0'}"><v-container style="width=50%" fluid>
            <v-row justify="center" no-gutters>
              <v-card-text align="center" class="mt-7 white--text">Please check your email for confirmation code</v-card-text>
              <v-col  cols=12 md=4>
                <v-text-field class="ma-3 font-weight-bold" v-model="newCode"   label="Confirmation Code" outlined rounded></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col  cols=12 md=4>
                <v-text-field :rules="[rules.required]" class="ma-3 font-weight-bold" v-model="newPassword" type="password"   label="Enter New Password" outlined rounded></v-text-field>
                <h3 v-if="errorForgotSubmit" align=center class="font-weight-bold red--text">{{ errorForgotSubmit }}</h3>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
                <v-btn class="ma-4" color="black" dark @click="forgotSubmit" rounded>Submit<v-icon color="white">mdi-file-send-outline</v-icon></v-btn>
            </v-row></v-container>
            <v-progress-linear v-if="apiRequest" indeterminate class="blue"></v-progress-linear>
          </v-card>
      </v-dialog>    
    </v-app>
</template>
<script>
import { Auth } from 'aws-amplify';
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
// import { createJudge } from '../graphql/mutations';
export default {
    data(){
        return {
            apiRequest: false,
            alertMissingInfo: "",
            confirmCode: null,
            dialogConfirm: false,
            dialogForgotSubmit: false,
            dialogLogin: true,
            dialogRegister: false,
            dialogForgot: false,
            

            errorLogin: "",
            errorRegister: "",
            errorConfirm: "",
            errorForgot: "",
            errorForgotSubmit: "",
            errorResendCode: "",

            messageLogin: null,
            newCode: "",
            newPassword: "",
            userName: "",
            userPassword: "",
            userEmail: "",
            // editorPassword: "",
            signedIn: false,
            // username: "",
            // user: undefined,
            // email: "",
        
            rules: {
                required: value => !!value || 'Required.',
                blank: value => !(/[ ]/.test(value)) || 'no spaces allowed',
                counter: value => value.length <= 20 || 'Max 20 characters',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
        }
    },
    computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
    methods:{

        ...mapActions({
          loginVuex: 'auth/login',
          signupVuex: 'auth/signUp',
          confirmsignupVuex: 'auth/confirmSignUp'
          
        }),
        backToLogin(){
            this.dialogRegister = false
            this.dialogLogin = true
        },
        async isUserSignedIn(){
            try {
                const userObj = await Auth.currentAuthenticatedUser()
                this.signedIn = true
                this.user = userObj
                this.teamName = this.user.attributes.nickname
            } catch (err) {
                this.signedIn = false
                this.dialogLogin = true
                this.apiRequest = false
            }
        },
        async signin(){
            try {
                this.showSlideShow = false
                this.apiRequest=true
                this.errorLogin = null
                this.messageLogin = null
                await this.loginVuex({ username: this.userName, password: this.userPassword })
                this.dialogLogin = false
                this.dialogRegister = false
                this.dialogForgot = false
                this.apiRequest=false
                // this.$emit('childClickedAuth', this.userName)
                // refresh screen after logging in
                // this.$router.go(0)
                this.$router.push("/calendar")
            } catch(err) {
                this.errorLogin = err.message
            }
        },
        async register(){
            if (!this.userName || !this.userEmail || !this.userPassword) {
                this.errorRegister = "Please fill in all the fields"
                this.alertMissingInfo = true
                return; }            
            this.apiRequest=true
            try {
                await this.signupVuex({
                    username: this.userName,
                    password: this.userPassword,
                    email: this.userEmail
                });  
                this.dialogRegister = false
                this.dialogConfirm = true
                this.dialogLogin = false
                
                this.apiRequest=false
            } catch (err) {
                if(err.message==='User already exists'){
                this.errorRegister = 'Player Name already exists' }
                else { this.errorRegister = err.message }
                console.log("error")
            }
        },
        async confirmSignUp(){
            this.dialogLogin = false
            this.apiRequest=true
            // console.log(this.userName)
            try {
              // next time, remove the wait of 3 seconds and have it sequential)
                await this.confirmsignupVuex({username: this.userName, code: this.confirmCode})
                this.signin()
                // this.isUserSignedIn()
                
                // var self = this
                // setTimeout(function () {
                // self.createEntry()
                // }, 3000);
                this.dialogConfirm = false
            } catch (err) {
                this.errorConfirm = err.message
            }
            this.apiRequest=false
            // console.log('here')
        },
        async resendCode(){
            this.apiRequest=true
            try {
                await Auth.resendSignUp(this.userName);
                this.errorConfirm = ""
                this.confirmCode = ""
                this.errorResendCode = ""
                this.apiRequest=false
            } catch (err) {
                this.errorResendCode = err.message
            }
        },
        async forgot(){
            try {
                await Auth.forgotPassword(this.userName)
                this.dialogForgot = false
                this.dialogLogin = false
                this.dialogForgotSubmit = true
            } catch(err){
                this.errorForgot = err.message
            }
        },
        async forgotSubmit(){
            this.apiRequest=true
            this.errorLogin = null
            this.messageLogin = null
            try {
                await Auth.forgotPasswordSubmit(this.userName, this.newCode, this.newPassword)
                this.userPassword = this.newPassword
                this.dialogForgotSubmit=false
                this.messageLogin = 'Succesful password reset... login again.'
                this.dialogLogin=true
                this.apiRequest=false
            } catch(err){
                this.errorForgotSubmit = err.message
            }
            this.apiRequest=false
        },
        async signOut() {
            try {
                await Auth.signOut();
                this.musicOff()
                this.drawer=false
                this.user=null
                this.signedIn=false
                this.dialogPhotos = false
                this.dialogLogin = true
                this.items = []
            } catch (err) {
                alert(err.message)
            }
        },
    }
}
</script>