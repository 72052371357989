<template>
  <div class="home">
    <Login v-if='!user'/>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import { mapGetters } from "vuex";
  export default {
      
    name: 'Home',

    components: {
      Login,  
    },
    // data:()=>({
    //   user: 'Eric'
    // }),
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    // mounted(){
    //   console.log(this.user)
    // }
  }
</script>
