/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a5ed58b2-27f7-4244-9ddf-6e71145cb362",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2T3uqAsvO",
    "aws_user_pools_web_client_id": "2l9l0mu1f9k9rh7shnio4daf1t",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://3ejne5e23vctrbuubr46khfqee.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
