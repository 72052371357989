import { API, graphqlOperation } from 'aws-amplify';
import { updateCalEvent, deleteCalEvent, createCalEvent } from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export const calEventInfo = {
    namespaced: true,
    state: {calevent: null },
    mutations: {
        setCalEvent(state, payload) {
            state.calevent = payload;
        }
    },
    actions: {
          async createCalEvent({ dispatch }, newCalEvent) {
            try {
                await API.graphql(graphqlOperation(createCalEvent, { input: newCalEvent }))
                dispatch("getCalEvent");
            } catch (error) {
                console.error("create calevent", error)
            }
            console.log('adding')
          },
          async getCalEvent({ commit }) {
              console.log("getCalEventA")
            const calEventData = await API.graphql(graphqlOperation(queries.listCalEvents));
            commit("setCalEvent", calEventData.data.listCalEvents.items);
        },
        async updateCalEvent({ dispatch }, input) {
            try {
                await API.graphql(graphqlOperation(updateCalEvent, { input: input }))
                dispatch("getCalEvent");
            } catch (error) {
                console.error("update calevent", error)
            }
            console.log('updateCalEvent')
        },
        async deleteCalEvent({ dispatch }, input) {
            try {
                await API.graphql(graphqlOperation(deleteCalEvent, { input: { id: input } }))
                dispatch("getCalEvent");
            } catch (error) {
                console.error("delete calevent", error)
            }
            console.log('deleteCalEvent')
        },
    },
    getters: {
        calevent: (state) => state.calevent
    }
}